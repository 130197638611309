<template>
  <div class="box">
    <div class="infos">
      <div class="titles">
        <div class="left">
          <span v-for="(item, index) in title" :key="index" :class="tabsIndex == index ? 'active' : ''" @click="getMallList(index)">{{ item.text + ': ' + item.num }}</span>
        </div>
        <div class="right">
          <div class="search">
            <i class="el-icon-search"></i>
            <input class="el-input__inner" @change="getMallList()" v-model="search" type="text" autocomplete="off" placeholder="输入名称搜索" />
          </div>
          <div class="search">
            <i class="el-icon-search"></i>
            <input class="el-input__inner" @change="getMallList()" v-model="idValue" type="text" autocomplete="off" placeholder="输入ID全称" />
          </div>
        </div>
      </div>
      <div class="list">
        <div class="item add" @click="create">
          <i class="iconfont el-icon-suitcase"></i>
          <div class="label">+ 新增项目</div>
        </div>
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="cont">
            <div class="top">
              <div class="image">
                <img alt="" :src="item.cover" />
                <div class="days">{{ item.endTime ? '剩 ' + shijianc(item.endTime, 'day') + ' 天' : '无限制' }}</div>
              </div>
              <div class="text">
                <div class="name">
                  <span class="title">{{ item.name }}</span>
                  <span class="tag">企业官网</span>
                </div>
                <div class="num">
                  ID：{{ item._id }}
                  <div></div>
                  已开通{{ item.pages.length }}个插件
                </div>
              </div>
            </div>
            <div class="info">
              <div class="row">
                <div class="col">
                  创建用户：
                  <div class="admin">
                    {{ item.userInfo[0] ? item.userInfo[0].username : '无' }} （手机号：{{ item.userInfo[0] ? item.userInfo[0].phone : '无' }}）
                    <!-- <span @click="open" class="unbind">点击解绑</span> -->
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">所属代理：{{ item.createType ? item.createType : 'user' }}</div>
              </div>
              <div class="deadline">
                <div class="time">剩余时间：{{ item.endTime ? shijianc(item.endTime) : '永久' }}（{{ item.endTime ? '剩 ' + shijianc(item.endTime, 'day') + ' 天' : '无限制' }}）</div>
                <div class="right">
                  <img src="../../../assets/image/e.png" alt="" />
                  <img src="../../../assets/image/applet.png" alt="" />
                  <img src="../../../assets/image/app.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="row bottom-hidden">
            <div class="col" @click="edit(item, index)">
              <span>
                <i class="el-icon-edit"></i>
                编辑
              </span>
            </div>
            <div class="col" @click="disable(item._id, item)">
              <span>
                <i class="el-icon-copy-document"></i>
                {{ item.disableType == 1 ? '解禁' : '禁用' }}
              </span>
            </div>
            <div class="col" @click="renew(item)">
              <span>
                <i class="el-icon-wallet"></i>
                续费
              </span>
            </div>
            <div class="col" @click="delect(item._id, item)">
              <span>
                <i class="el-icon-delete"></i>
                {{ item.recycle === 1 ? '移出' : '删除' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-pagination @size-change="numChange" @current-change="pagination" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="pageLimit" :current-page="page" :pager-count="4" layout="total, sizes, prev, pager, next, jumper" :total="totalSize"></el-pagination>
      </div>
    </div>

    <!-- 新建 -->
    <create-dialog ref="create"></create-dialog>

    <!-- 续费 renew -->
    <renew-manage ref="renewDialog" @refreshData="getMallList"></renew-manage>
  </div>
</template>

<script>
import CreateDialog from '@/components/CreateDialog'
import renewManage from './renew-manage'
import { mapMutations } from 'vuex'
import http from '@/utils/cloud'
import global from '@/config/global'

export default {
  components: {
    CreateDialog,
    renewManage
  },
  data() {
    return {
      list: [],
      page: 1,
      pageLimit: 20,
      search: '',
      idValue: '',
      tabsIndex: 0,
      title: [
        {
          text: '全部',
          num: 0
        },
        {
          text: '使用中',
          num: 0
        },
        {
          text: '试用中',
          num: 0
        },
        {
          text: '禁用',
          num: 0
        },
        {
          text: '将过期',
          num: 0
        },
        {
          text: '已过期',
          num: 0
        },
        {
          text: '回收站',
          num: 0
        }
      ]
    }
  },
  //监听函数
  watch: {
    $route() {
      // 跳转恢复初始值
      this.page = 1
      this.pageLimit = 20
      this.search = ''
      this.idValue = ''
      this.getMallList(0) //我的初始化方法
    }
  },
  created() {
    this.getMallList(this.tabsIndex)
  },
  methods: {
    ...mapMutations(['setProject']),
    // 创建商城
    create() {
      localStorage.setItem('routerType', 'admin')
      this.$refs['create'].open('admin')
    },
    // 获取商城列表
    async getMallList(index) {
      if (index || index == 0) {
        this.tabsIndex = index
      }
      const { page, pageLimit } = this
      const send = {
        offset: (page - 1) * pageLimit,
        limit: pageLimit,
        search: this.search,
        idValue: this.idValue,
        index: this.tabsIndex
      }
      const value = this.$route.query.index
      if (value == 2) send.createType = 'user'
      if (value == 3) send.createType = 'admin'
      const res = await http.GET('/jw-admin', {
        module: 'project',
        operation: 'getList',
        ...send
      })
      this.totalSize = res.affectedDocs
      this.list = res.data
      this.getNums(value)
    },
    // 获取数量
    getNums(value) {
      const send = {}
      if (value == 2) send.createType = 'user'
      if (value == 3) send.createType = 'admin'
      this.title.map(async (item, index) => {
        const res = await http.GET('/jw-admin', {
          module: 'project',
          operation: 'getNums',
          index: index,
          ...send
        })
        return (item.num = res.total)
      })
    },
    // 编辑商城
    edit(project, index) {
      this.setProject(project)

      this.$router.push('/mall/home/homeData?type=admin')
    },
    // 每页显示个数改变
    numChange(e) {
      this.pageLimit = e
      this.getMallList()
    },
    // 当前页数改变
    pagination(e) {
      this.page = e
      this.getMallList()
    },
    // 绑定
    open() {
      const name = '123'
      const value = '17854227736（17854227736）'
      this.$confirm(`确认是否解除${name}绑定的管理员：${value}？`, '提示', {
        confirmButtonText: '删除',
        type: 'warning'
      })
        .then(async () => {
          console.log('解绑')
        })
        .catch(() => {})
    },
    // 续费
    renew(item) {
      this.$refs.renewDialog.formData = JSON.parse(JSON.stringify(item))
      this.$refs.renewDialog.renewVisible = true
    },
    // 删除
    delect(id, item) {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: '当前是演示环境，此设置只可参看，无法修改~',
          type: 'warning',
          center: true
        })
        return
      }
      let text = ''
      let value = 1
      if (item.recycle === 1) {
        text = '移出回收站'
        value = 0
      } else {
        text = '移入回收站'
      }
      if (process.env.NODE_ENV == 'production') {
        if (item.del == 2) {
          this.$message.error('演示项目不可删除')
          return
        }
      }
      this.$confirm(`确认是否把项目${item.name},${text}吗？`, '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          http
            .POST('/jw-admin', {
              module: 'project',
              operation: 'deleteCategory',
              id: id,
              recycle: value
            })
            .then(response => {
              this.$message.success(response.msg)
              this.getMallList()
            })
        })
        .catch(() => {})
    },
    // 禁用
    disable(id, item) {
      let text = ''
      let value = 1
      if (item.disableType == 1) {
        text = '取消禁用'
        value = 0
      } else {
        text = '禁用'
      }
      this.$confirm(`确认是否把项目${item.name},${text}吗？`, '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async () => {
          http
            .POST('/jw-admin', {
              module: 'project',
              operation: 'disableTypeChange',
              id: id,
              disableType: value
            })
            .then(response => {
              this.$message.success(response.msg)
              this.getMallList()
            })
        })
        .catch(() => {})
    },
    // 年-月-日
    shijianc(time, value) {
      var date = new Date(time)
      var nowTime = +new Date()
      if (value == 'day') {
        if (time - nowTime < 0) {
          return 0
        } else {
          return Math.floor((time - nowTime) / (1000 * 3600 * 24))
        }
      }
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1800px) {
  .list {
    .item {
      width: calc(50% - 10px) !important;
      margin-right: 20px !important;
      &:nth-child(2n) {
        margin-right: 0 !important;
      }
    }
  }
}
::v-deep .el-dialog {
  // width: 30%;
  .el-input {
    width: 80%;
  }
  .el-dialog__body {
    padding-top: 10px;
  }
}
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  background-color: #f4f6f8;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
  .infos {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
    .titles {
      display: flex;
      justify-items: center;
      margin-bottom: 20px;
      .left {
        width: calc(100% - 200px);
        span {
          display: inline-block;
          height: 36px;
          padding: 0 22px;
          margin-right: 10px;
          line-height: 36px;
          color: #20303f;
          text-align: center;
          cursor: pointer;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
        }
        .active {
          color: #fefefe;
          background: #6954f0;
        }
      }
      .right {
        width: 280px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        .search {
          width: 100%;
          height: 36px;
          line-height: 36px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          padding: 1px 11px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          &:last-child {
            margin-left: 6px;
          }
          i {
            display: inline-flex;
            white-space: nowrap;
            flex-shrink: 0;
            flex-wrap: nowrap;
            text-align: center;
            color: #a8abb2;
            transition: all 0.3;
            pointer-events: none;
            margin-right: 8px;
          }
        }
        .el-input__inner {
          width: 100%;
          height: 36px;
          flex-grow: 1;
          color: #606266;
          padding: 0;
          border: none;
          font-size: 14px;
        }
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      .item {
        width: calc(33.33333% - 14px);
        min-height: 208px;
        margin: 0 20px 20px 0;
        cursor: pointer;
        background: #fff;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:not(:nth-child(1)):hover {
          border-left: 4px solid #6954f0;
          border-radius: 10px;
        }
        &:hover {
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 20%);
        }
        .cont {
          position: relative;
          padding: 20px;
          .top {
            display: flex;
            .image {
              position: relative;
              width: 55px;
              height: 55px;
              > img {
                width: 55px;
                height: 55px;
                border-radius: 6px;
                object-fit: cover;
              }
              .days {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: #fff;
                text-align: center;
                background: rgba(0, 0, 0, 0.45);
                border-radius: 0 0 5px 5px;
              }
            }
            .text {
              position: relative;
              width: calc(100% - 55px);
              margin-left: 12px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .name {
                display: flex;
                align-items: center;
                .title {
                  width: calc(100% - 80px);
                  height: 26px;
                  overflow: hidden;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 26px;
                  color: #20303f;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .tag {
                  max-width: 80px;
                  height: 26px;
                  padding: 0 11px;
                  overflow: hidden;
                  font-size: 13px;
                  line-height: 26px;
                  color: #f56c6c;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  background: rgba(245, 108, 108, 0.1);
                  border-radius: 13px;
                }
              }
              .num {
                font-size: 14px;
                color: #20303f;
                display: flex;
                align-items: center;
                div {
                  width: 1px;
                  height: 100%;
                  margin: 0 8px;
                  vertical-align: middle;
                  border-left: 1px solid #dcdfe6;
                }
              }
            }
          }
          .info {
            margin-top: 30px;
            font-size: 14px;
            color: #6b7c8c;
            .row:not(:last-child) {
              margin-bottom: 14px;
            }
            .admin {
              display: inline-block;
              &:hover {
                color: #182430;
                .unbind {
                  visibility: visible;
                  color: #5536ed;
                }
              }
              .unbind {
                visibility: hidden;
              }
            }
            .deadline {
              display: flex;
              .time {
                flex: 1;
              }
              .right {
                display: flex;
                align-items: center;
                > img {
                  width: 20px;
                  height: 20px;
                  margin-left: 10px;
                  &:first-child {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
        .bottom-hidden {
          height: 0;
          overflow: hidden;
          line-height: 41px;
          text-align: center;
          background: #fff;
          border-color: transparent;
          border-top: 0;
          border-radius: 0 0 10px 10px;
          transition: all 0.4s ease-in-out;
          .col:not(:last-child) {
            font-size: 14px;
            color: #6b7c8c;
            border-right: 1px solid #ebedf5;
          }
          .col {
            max-width: 25%;
            flex: 0 0 25%;
            i {
              color: #90a1b1;
              font-size: 16px;
            }
            &:hover {
              color: #5536ed;
              i {
                color: #5536ed;
              }
            }
          }
        }
        &:hover {
          .bottom-hidden {
            height: 41px;
            line-height: 41px;
            border: 1px solid #ebedf5;
          }
        }
      }
      .add {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        i {
          font-size: 40px;
          color: #90a1b1;
        }
        .label {
          margin-top: 16px;
          color: #20303f;
          font-size: 14px;
        }
      }
    }
  }
}
.all {
  &.is-checked {
    ::v-deep .el-checkbox__inner {
      background-color: #6954f0 !important;
      border-color: #6954f0 !important;
    }
    ::v-deep .el-checkbox__label {
      color: #6954f0;
    }
  }
}
.types {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: column;
    span {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #20303f;
    }
    .detail {
      display: flex;
      flex-wrap: wrap;
      .el-checkbox-group {
        .el-checkbox {
          position: relative;
          width: 109px;
          height: 36px;
          padding: 0 4px;
          margin-right: 20px;
          margin-bottom: 20px;
          line-height: 36px;
          color: #20303f;
          text-align: center;
          cursor: pointer;
          background: #fafbfc;
          border: 1px solid #ebedf5;
          border-radius: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          ::v-deep .el-checkbox__input {
            display: none;
          }
          ::v-deep .el-checkbox__label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .corner-icon {
              display: none;
            }
          }
        }
        .is-checked {
          background: #edeafd;
          ::v-deep .el-checkbox__label {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #20303f;
            .corner-icon {
              display: flex;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 3;
              width: 18px;
              height: 18px;
              line-height: 15px;
              text-align: center;
              background: #6954f0;
              border-radius: 0 0 0 18px;
              i {
                font-size: 13px;
                color: #fff;
                line-height: 15px;
                justify-content: center;
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
